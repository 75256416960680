import React from 'react'
export const languages = {
    en: {
        contact:  {
            title: 'Contact',
            name: 'Name',
            email: 'Email',
            message: 'Message',
            submit: 'Submit'
        },
        home: {
            welcomeText: `Welcome to my website!`,
            descriptionText: `I'm a web developer, creative, innovative and apassionate with my work!`
        },
        experience: {
            description: `Actually, I'm working like a backend and frontend developer on technologies like nodejs, Vue, ReactJs 
            and in the last years (+2) on the DevOps side integrating tools for (CI/CD) with technologies like Docker and Gitlab 
            also I've experience with Cloud Providers AWS and GCP.`,
            frontEnd: `Front-end, Actually I´ve experience with frameworks/libraries like reactjs, vue, jquery and some others also I have experience on mobile development with react-native or cordova.`,
            frontEndPractice:`Actually, I'm working with two principals technologies react and vue.`,        
            backEnd: `Now Back-End, I've a wide experience working with applications on nodejs and databases like MongoDB, Mysql, and others also I've experience with cloud providers like AWS or GCP.`,
            backEndPractice:`Actually, I've working with much technologies (Docker, AWS, GCP, Gitlab(CI))`,         
            devOps: 'Finalmente, DevOps, he estado implementando en mis proyectos el flujo de Integracion Continua(CI) para el deploy de los proyectos. asi como tambien herramientas para el monitoreo y algunos otras herramientas y servicios que nos ofrece proveedores como AWS.',
            devOpsPractice:'Esta es una area que me ha interesado por los beneficios que nos ofrece el tener un flujo de Integracion Continua sobre nuestros proyectos.',         
        },
        project: {
            title1: `Codellege`,
            description1: `I'm a mentor in the "codellege" program,
            I mentoring young people to become developers and 
            they've more and best opportunities for a job.`,
            title2: `Portfolio`,
            description2: `This presentacion page was made by me, I use technologies like GCP(Cloud Storage, Cloud functions, etc) 
            in this section I'll update public projects.`,   
            visit: 'Visit'
        }, 
        messages: {
            contactSuccess: 'I will contact you as soon as possible',
            errorSuccess: 'Ups, an error ocurred, you can contact me to jaguz.guzman@gmail.com.'
        } 
    },
    es: {
        contact: {
            title: 'Contacto',
            name: 'Nombre',
            email: 'Correo',
            message: 'Mensaje',
            submit: 'Enviar'
        },
        home: {
            welcomeText: `Bienvenido a mi sitio Web!`,
            descriptionText: `Soy un desarrollador web, creativo, innovador y apasionado con mi trabajo!`
        },
        experience: {
            description: `Actualmente, estoy trabajando como desarrollador back-end y front-end en tecnologias como Nodejs, Vue, React y en los ultimos años (+2) he tenido experiencia en el area de DevOps,
            integrando herramientas de (CI/CD) y usando tecnologias como Docker y Gitlab ademas de tener experiencia con servicios en la nube como AWS y GCP por lo que me siento con la suficiente experiencia,
            para afrontar cualquier desarrollo o tecnologia.`,  
            frontEnd: 'En area de Front-end, actualmente tengo experiencia con frameworks/librerias como React y Vue entre otras.',
            frontEndPractice:'Es un area en donde me siento muy comodo implementado cualquier clase de solucion desde una Web app hasta una mobile app.',        
            backEnd: 'En area de Back-End, tengo una amplia experiencia trabajando con aplicaciones en nodejs, con bases de datos como MongoDB asi como integrando servicios de AWS o de algun otro tipo.',
            backEndPractice:'He manejado por un largo tiempo applicaciones en nodejs, tambien tengo experiencia con servicios en la nube como AWS, GCP ademas de usar tecnologias como Docker y Gitlab para el flujo de "Continuous Integration" and "Continuous Delivery".',         
            devOps: 'Finalmente, DevOps, he estado implementando en mis proyectos el flujo de Integracion Continua(CI) para el deploy de los proyectos. asi como tambien herramientas para el monitoreo y algunos otras herramientas y servicios que nos ofrece proveedores como AWS.',
            devOpsPractice:'Esta es una area que me ha interesado por los beneficios que nos ofrece el tener un flujo de Integracion Continua sobre nuestros proyectos.',         
        },
        project: {
            title1: `Codellege`,
            description1: `Soy mentor en el programa "codellege"
            en el cual enseño a jovenes programar para que puedan 
            tener mejores oportunidades en su vida laboral.`,
            title2: `Portafolio`,
            description2: `Esta pagina de presentacion fue hecha por mi, haciendo uso de uso de tecnologias como GCP (Cloud Storage, Cloud functions, etc) 
            en esta seccion estare actualizando los proyectos que puedan ser de dominio publico.`,   
            visit: 'Visitalo'
             
        },  
        messages: {
            contactSuccess: 'Te contactare lo mas pronto que pueda.',
            errorSuccess: 'Ups, ocurrio un error, puedes contactar a jaguz.guzman@gmail.com.'
        } 
    }
}
export const LanguageContext = React.createContext({
	langText: languages.en,
	changeLanguage: () => { }
});
