import React, { Suspense, lazy } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Redirect } from 'react-router'
import Layout from '../components/Layout/Layout'
import Loading from '../components/Loading/Loading'
const ComponentWrapper = ( Component ) => {
    return props => (
        <Suspense fallback={<Loading/>}>
          <Component {...props} />
        </Suspense>
      );
}
const redirectComponent = ( Component, props ) => {
    return () => (
          <Component {...props} />
      );
}
const validateUserRoles = ( component, route ) =>{
    const userAuth = { data: { roles:['admin'] } }//check roles from a server await axios.get(`/user/me`);
    const validUser = route.allowedRoles.some(allowedRole => userAuth.data.roles.some(userRole => userRole === allowedRole));
    console.log(validUser);    
    if(validUser){
        return component;
    }
    else {
        return redirectComponent(Redirect, {to: '/'});
    }

}
const validateAuthentication = ( component, route ) =>{
    const requiresAuth = route.requiresAuth;
    if (requiresAuth) {
        return validateUserRoles(component,route);
    } else {
        return component;    
    }
}

const routes = [{
    path: '/',
    layout: Layout,
    component:  ComponentWrapper(lazy(() => import('../containers/Home'))),
    roles: {
        requiresAuth: false,
        allowedRoles: []
    },
    config: {
        exact: true
    }
},
{
    path: '/experience',
    layout: Layout,
    component:  ComponentWrapper(lazy(() => import('../containers/Experience'))),
    roles: {
        requiresAuth: false,
        allowedRoles: []
    },
    config: {
        exact: true
    }
},
{
    path: '/project',
    layout: Layout,
    component:  ComponentWrapper(lazy(() => import('../containers/Project'))),
    roles: {
        requiresAuth: false,
        allowedRoles: []
    },
    config: {
        exact: true
    }
},
{
    path: '/contact',
    layout: Layout,
    component:  ComponentWrapper(lazy(() => import('../containers/Contact'))),
    roles: {
        requiresAuth: false,
        allowedRoles: []
    },
    config: {
        exact: true
    }
},
{
    path: '*',
    layout: Layout,
//    component: ComponentWrapper(lazy(() => import('../containers/Error404'))),    
    component: redirectComponent(Redirect, {to: '/'}),
    roles: {
        requiresAuth: false,
        allowedRoles: []
    },
    config:{
        exact: true
    }
}]

const routerWithRoutes = () => {
    return (
        <Router>
            <Switch>
            {
                routes.map(({path, component, roles, layout, config})=>(<Route key={path} path={path} component={(validateAuthentication(layout(component), roles))} {...config}/>))
            }
            </Switch>
        </Router>
    ) 
}
export default routerWithRoutes