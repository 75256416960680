import React, { useState } from 'react';
import './App.css';
import routes from './routes'
import { LanguageContext, languages } from './context/lang'
const App = (props) => {

  let language = localStorage.getItem('lang') || window.navigator.language;
  const [lang, setLang] = useState({langText: (!language.includes('es')) ? languages.en : languages.es})

  function changeLanguage(){
    language = language.includes('es') ? 'en' : 'es'     
    localStorage.setItem('lang', language);
    setLang({langText: languages[language]})
  }

  return(  
  <LanguageContext.Provider value={{langText: lang.langText, changeLanguage: changeLanguage}}>
  <div className="App">
    {
      routes()
    }
  </div>
  </LanguageContext.Provider>
  )
}
export default App;
