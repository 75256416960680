import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
        color: '#fff',
    },
    intro: {
        background: 'linear-gradient(#2c3e50 40%,#32678b)'
    },
})
const Loading = (props) => {
    const { classes } = props;
    return(
        <Grid 
            className={[classes.intro].join(" ")}
            container 
            direction="row"
            justify="center"
            alignItems="center"
            style={{ height: '100vh' }}
        >
            <Grid
                item
                xs={12}
                
            >          
                <CircularProgress className={classes.progress} size={30} thickness={5} />
            </Grid>
        </Grid>
    )
}
export default withStyles(styles)(Loading);