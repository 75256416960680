import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { LanguageContext } from '../../../context/lang'

export const styles = theme => ({
    footerSectionContainer:{
        label: 'footer',
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        textAlign: 'end',
        fontSize: '0.7em',
        borderTop: '1px solid #3c759b',
        fontFamily: "'Poiret One', 'sans-serif'",
    },
    footerSection:{
        backgroundColor: '#293347'  
    }, 
    footerIcons: {
        height: '1.7em',
        margin: '5px'
    },
    footerIconColor: {
        fill: 'white'
    },
    footerText: {
        fontFamily: '"Poiret One", Helvetica, sans-serif',
        color: 'white',
        fontSize: '1rem',	
    }
  });
  
const Footer = (props) =>{
    const { classes } = props;
    return (
    <LanguageContext.Consumer>
    {({changeLanguage}) =>(
    <Grid 
        container
        className={classes.footerSection}
        direction="row"
        justify="center"
        alignItems="center"
    >
        <Grid 
            item
            xs={6}
        >
            <a href="https://www.linkedin.com/in/jonathanguzmanrdz/" target="_blank" rel="noopener noreferrer">
                <svg className={classes.footerIcons} role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn icon</title><path className={classes.footerIconColor} d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>        
            </a>
            <a href="https://gitlab.com/jonathanGuzman" target="_blank" rel="noopener noreferrer">
                <svg className={classes.footerIcons} role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>GitLab icon</title><path className={classes.footerIconColor} d="M23.955 13.587l-1.342-4.135-2.664-8.189c-.135-.423-.73-.423-.867 0L16.418 9.45H7.582L4.919 1.263C4.783.84 4.185.84 4.05 1.26L1.386 9.449.044 13.587c-.121.375.014.789.331 1.023L12 23.054l11.625-8.443c.318-.235.453-.647.33-1.024"/></svg>                    
            </a>
        </Grid>
        <Grid 
            item
            xs={6}
        >
        <Typography className={classes.footerText} variant="subtitle2" gutterBottom>
        A project by Jonathan Guzman 
        <span style={{marginLeft: 5}}  role="img" aria-label="emoji">
        ✌️
        </span>
        </Typography>        
        </Grid>
    </Grid>)
    }
    </LanguageContext.Consumer>
    )
}
export default withStyles(styles)(Footer);